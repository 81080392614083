<template>
  <v-container fluid>
    <div class="from-content">
      <div class="from">
        <v-form ref="form">
          <v-row>
            <v-col cols="6" md="6">
              <v-text-field
                  @keydown.enter="focusNext"
                  dense
                  outlined
                  :label="$t('Currency.form.currencyName')"
                  v-model="currency.name"
                  :rules="nameRules"
                  required
              >
              </v-text-field>
            </v-col>
            <v-col cols="6" md="6">
              <v-text-field
                  @keydown.p.prevent.enter="ValidateForm"
                  dense
                  outlined
                  :label="$t('Currency.form.currencyCode')"
                  v-model="currency.short"
                  :rules="nameRules"
                  required
              >
              </v-text-field>
            </v-col>
            <v-col cols="8">
              <v-btn class="btn-save-change" @click="ValidateForm">
                {{ $t("Currency.form.update") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </div>
    </div>
  </v-container>
</template>

<script>
import {mapActions} from 'vuex'

export default {
  data() {
    return {
      nameRules: [
        v => !!v || 'field is required...'
      ],
    }
  },
  methods: {
    ...mapActions({
      getCurrency: "Currency/filterCurrency"
    }),

    focusNext(e) {
      const inputs = Array.from(e.target.form.querySelectorAll('input[type="text"]'));
      const index = inputs.indexOf(e.target);
      if (index < inputs.length) {
        inputs[index + 1].focus();
      }
    },

    ValidateForm() {
      if (this.$refs.form.validate()) {
        this.UpdateCurrency();
      }
    },
    UpdateCurrency() {
      const currency = this.currency
      this.$store.dispatch('Currency/UpdateCurrency', currency)
      setTimeout(() => {
        this.$notification.OpenNotification_EditItem_OnSuccess('top-right', 'primary', 3000);
      }, 300)

      setTimeout(() => {
        this.resetForm()
        this.$router.push({name: "currency.index"}).catch(() => {
        })
      }, 300)
    },
    resetForm() {
      this.$refs.form.reset();
    },
  },
  computed: {
    currency() {
      return this.$store.getters["Currency/getCurrency"]
    }
  }
}
</script>

<style scoped lang="scss">
.container {
  width: 100%;
  padding: 0 10px 0 10px;
  margin-right: auto;
  margin-left: auto;

}

.from-content {
  width: 100%;
  height: 100vh;
  padding: 40px 50px;
  display: flex;
  justify-content: center;
  align-items: center;

  .from {
    width: 800px;
    height: 500px;
  }

}
</style>
